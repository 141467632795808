<template>
  <div class="container">
    <Navtop></Navtop>
    <Navbar></Navbar>
    <router-view></router-view>
  </div>
</template>

<script>
// import SelectClient from "../store-module/clients/Select";
import { mapState } from "vuex";
import Navtop from "./Navtop.vue";
import Navbar from "./Navbar.vue";

export default {
  components: {
    // SelectClient
    Navtop,
    Navbar
  },
  computed: {
    ...mapState({
      menu: (s) => s.config.menu.dental_clinic,
      load_all: (s) => s.config.user.can_load_all_menus
    })
  },
  watch: {
    client_id(to) {
      if (to) {
        this.$router.push({
          path: "/dental-clinic/patients/" + to
        });
      }
    }
  },
  data() {
    return {
      client_id: undefined,
      entity_id: undefined
    };
  }
};
</script>

<style>
.app-den-search-client {
  display: inline-block;
  max-width: 500px;
}

.custom_container {
  width: 1140px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}
</style>
