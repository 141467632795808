<template>
  <nav class="navbar navbar-expand navbar-dark bg-primary p-2">
    <a class="navbar-brand mx-2 app_title" href="#">{{appName}}</a>
    <MainSearch></MainSearch>
    <ul class="navbar-nav">
      <li class="nav-item dropdown">
        <a
          class="nav-link pr-0 "
          href="#"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="media align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <!-- <img
                  alt="Image placeholder"
                  src="../assets/img/theme/team-4-800x800.jpg"
                /> -->
              <app-img-viewer
                class="rounded-circle"
                alt="Profile image"
                :src="pathAvatar"
                :showZoom="false"
              ></app-img-viewer>
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold  dropdown-toggle">
                {{ user.name }}
              </span>
            </div>
          </div>
        </a>
        <UserDropdownMenu></UserDropdownMenu>
      </li>
    </ul>
  </nav>
</template>
<script>
import MainSearch from "./ClientMainSearch.vue";
import UserDropdownMenu from "../components/UserDropdownMenu.vue";
import { userImgGetter } from "../general-module/user/userImgGetter";
import { mapState } from "vuex";

export default {
  components: {
    MainSearch,
    UserDropdownMenu
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    //
  }),

  computed: {
    ...mapState({
      user: s => s.user,
      pathAvatar: userImgGetter
    }),
    appName() {
      return process.env.VUE_APP_NAME
    }
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style scoped>
.app_title {
  font-weight: 600;
}
</style>

