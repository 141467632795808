<template>
  <vue-simple-suggest
    v-model="model"
    :list="search"
    :debounce="300"
    class="w-100"
    ref="suggestComponent"
    display-attribute="name"
    value-attribute="id"
    @select="afterSelect($event)"
  >
    <!-- @select="afterSelect($event)" -->
    <input
      type="search"
      class="form-control form-control-alternative min_long"
      v-app-input-focused
      @click="$event.target.value = ''"
      :placeholder="placeholder"
    />
    <router-link
      slot="suggestion-item"
      slot-scope="scope"
      :to="{
        name: 'client-profile',
        params: { client_id: scope.suggestion.id }
      }"
      class="text-reset w-100 d-block"
    >
      <span
        v-html="
          boldenSuggestion({
            suggestion: scope.suggestion.name,
            query: scope.query
          })
        "
      ></span>
    </router-link>
  </vue-simple-suggest>
</template>
<script>
// import { StoreService } from "../store-module/service";
// import { boldenSuggestion } from "../utils/boldenSuggestion";
import { DentalClinicService } from "./service";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    placeholder: { default: "Buscar paciente por Nombre, DNI, Codigo" }
  },

  data: () => ({
    model: null
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    afterSelect({ id }) {
      this.$router.push(`/dental-clinic/patients/${id}`);
    },
    boldenSuggestion(scope) {
      if (!scope) return scope;
      const { suggestion, query } = scope;
      // let result = this.$refs.suggestComponent.displayProperty(suggestion);
      let result = suggestion;
      if (!query) return result;
      const texts = query.split(/[\s-_/\\|.]/gm).filter((t) => !!t) || [""];
      return result.replace(
        new RegExp("(.*?)(" + texts.join("|") + ")(.*?)", "gi"),
        "$1<b>$2</b>$3"
      );
    },
    async search(search) {
      var result = await DentalClinicService.getClients({ search });
      return result.data.map((x) => {
        var code = "";
        if (x.code) {
          code = x.code + " ";
        }
        x.name = `${code}${x.name}`;
        return x;
      });
      // return [
      //   "rjgwegt",
      //   "globalThisrt",
      //   "gretger",
      //   "TreatmentVueg",
      //   "ErrorEventg",
      //   "ErrorEventger",
      //   "trueg"
      // ];
    }
  }
};
</script>

<style scoped>
.min_long {
  min-width: 400px;
}
</style>
