<template>
  <nav
    class="navbar navbar-expand navbar-light bg-white shadow-sm d-block p-1 d-print-none mb-1"
  >
    <ul class="navbar-nav">
      <template v-for="(l, i) in menu">
        <template v-if="!l.l">
          <li class="nav-item" :key="i" v-show="l.can_load || all">
            <router-link :to="l.p" class="nav-link">{{ l.t }}</router-link>
          </li>
        </template>
        <template v-else>
          <li class="nav-item dropdown" :key="i" v-show="l.can_load || all">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              >{{ l.t }}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link
                v-for="(l1, i1) in l.l"
                :key="i1"
                :to="l1.p"
                class="dropdown-item"
                v-show="l1.can_load || all"
                >{{ l1.t }}</router-link
              >
            </div>
          </li>
        </template>
      </template>

      <li class="nav-item" v-show="hasPermission('logistic')">
        <a :href="logisticModule" class="nav-link">
          Logistica
        </a>
      </li>
    </ul>
    <!--
  <div class="btn-block btn-group mb-2">
    <template v-for="(l, i) in menu">
      <template v-if="l.l">
        <div class="dropdown" :key="i">
          <a
            class="btn btn-light dropdown-toggle btn-sm rounded-0 text-nowrap"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            href="javascript:void(0)"
          >
            {{ l.t }}
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <router-link
              v-for="(l1, i1) in l.l"
              :key="i1"
              class="dropdown-item"
              :to="l1.p"
              >{{ l1.t }}</router-link
            >
          </div>
        </div>
      </template>
      <template v-else>
        <router-link
          :to="l.p"
          :key="i"
          class="btn btn-light btn-sm rounded-0 text-nowrap"
        >
          {{ l.t }}
        </router-link>
      </template>
    </template>
  </div>-->
  </nav>
</template>
<script>
import { mapState } from "vuex";

export default {
  components: {
    //
  },
  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    //
  }),

  computed: {
    ...mapState({
      all: (s) => s.config.user.can_load_all_menus,
      menu: (s) => s.config.dental.menu.l // dental menu list
    }),
    logisticModule() {
      return this.$store.state.config.logistic.client.url;
    }
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    hasPermission(permissions) {
      return this.$store.state.permissions.includes(permissions);
    }
  }
};
</script>

<style scoped></style>
