var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-simple-suggest',{ref:"suggestComponent",staticClass:"w-100",attrs:{"list":_vm.search,"debounce":300,"display-attribute":"name","value-attribute":"id"},on:{"select":function($event){return _vm.afterSelect($event)}},scopedSlots:_vm._u([{key:"suggestion-item",fn:function(scope){return _c('router-link',{staticClass:"text-reset w-100 d-block",attrs:{"to":{
      name: 'client-profile',
      params: { client_id: scope.suggestion.id }
    }}},[_c('span',{domProps:{"innerHTML":_vm._s(
        _vm.boldenSuggestion({
          suggestion: scope.suggestion.name,
          query: scope.query
        })
      )}})])}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('input',{directives:[{name:"app-input-focused",rawName:"v-app-input-focused"}],staticClass:"form-control form-control-alternative min_long",attrs:{"type":"search","placeholder":_vm.placeholder},on:{"click":function($event){$event.target.value = ''}}})])}
var staticRenderFns = []

export { render, staticRenderFns }